var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Identificativi",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-radio", {
                                                        attrs: {
                                                          name: "tipo",
                                                          label:
                                                            "Seleziona Tipo",
                                                          options:
                                                            _vm.salesman_types,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.salesman_type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.salesman_type =
                                                              $$v
                                                          },
                                                          expression:
                                                            "salesman_type",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.isPhisical
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-input", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_SURN_value
                                                                .label,
                                                              vid: "attribute_SURN_value",
                                                              label:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_SURN_value
                                                                  .label,
                                                              placeholder:
                                                                "Inserisci un cognome",
                                                              rules:
                                                                _vm.isPhisical
                                                                  ? {
                                                                      required: true,
                                                                    }
                                                                  : {},
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_SURN_value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "attribute_SURN_value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].attribute_SURN_value",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.isPhisical
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-input", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_NAME_value
                                                                .label,
                                                              vid: "attribute_NAME_value",
                                                              label:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_NAME_value
                                                                  .label,
                                                              placeholder:
                                                                "Inserisci un nome",
                                                              rules:
                                                                _vm.isPhisical
                                                                  ? {
                                                                      required: true,
                                                                    }
                                                                  : {},
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_NAME_value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "attribute_NAME_value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].attribute_NAME_value",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.isPhisical
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-input", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_CNAM_value
                                                                .label,
                                                              vid: "attribute_CNAM_value",
                                                              label:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_CNAM_value
                                                                  .label,
                                                              placeholder:
                                                                "Inserisci una ragione sociale",
                                                              rules:
                                                                !_vm.isPhisical
                                                                  ? {
                                                                      required: true,
                                                                    }
                                                                  : {},
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_CNAM_value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "attribute_CNAM_value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].attribute_CNAM_value",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.isPhisical
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-input", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_CREG_value
                                                                .label,
                                                              vid: "attribute_CREG_value",
                                                              label:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_CREG_value
                                                                  .label,
                                                              placeholder:
                                                                "Inserisci una partita IVA",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_CREG_value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "attribute_CREG_value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].attribute_CREG_value",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_NINO_value
                                                            .label,
                                                          vid: "attribute_NINO_value",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_NINO_value
                                                              .label,
                                                          placeholder:
                                                            "Inserisci un codice fiscale",
                                                          textcase: "upper",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_NINO_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_NINO_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_NINO_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "attribute_CLI_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_CLI_value
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_CLI_value
                                                              .label,
                                                          options:
                                                            Object.values(
                                                              _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_CLI_value
                                                                .options
                                                            ).map((o) => {
                                                              return {
                                                                value: o.value,
                                                                text: o.text,
                                                              }
                                                            }),
                                                          rules: _vm.getRules(
                                                            "attribute_SEEX_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_CLI_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_CLI_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_CLI_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "attribute_SUP_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_SUP_value
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_SUP_value
                                                              .label,
                                                          options:
                                                            Object.values(
                                                              _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_SUP_value
                                                                .options
                                                            ).map((o) => {
                                                              return {
                                                                value: o.value,
                                                                text: o.text,
                                                              }
                                                            }),
                                                          rules: _vm.getRules(
                                                            "attribute_SUP_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_SUP_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_SUP_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_SUP_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("address-book-form", {
                                          attrs: {
                                            beECForm: _vm.beForm[_vm.addrBook],
                                            repository: _vm.addrBook,
                                          },
                                          model: {
                                            value: _vm.address_book_form,
                                            callback: function ($$v) {
                                              _vm.address_book_form = $$v
                                            },
                                            expression: "address_book_form",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("correspondence-form", {
                                          attrs: {
                                            beECForm: _vm.beForm[_vm.cor],
                                            repository: _vm.cor,
                                          },
                                          model: {
                                            value: _vm.correspondence_form,
                                            callback: function ($$v) {
                                              _vm.correspondence_form = $$v
                                            },
                                            expression: "correspondence_form",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.resetForm()
                                                  },
                                                },
                                              },
                                              [_vm._v("Reset")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            800184450
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }